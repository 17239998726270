import { Transition } from '@unseenco/taxi'

export default class FadeTransition extends Transition {
	/**
	 * Handle the transition leaving the previous page.
	 * @param { { from: HTMLElement, trigger: string|HTMLElement|false, done: function } } props
	 */
	async onLeave({ done }) {
		done()
	}

	/**
	 * Handle the transition entering the next page.
	 * @param { { to: HTMLElement, trigger: string|HTMLElement|false, done: function } } props
	 */
	async onEnter({ to, done }) {
		const keyframes = [{ opacity: 0 }, { opacity: 1 }]
		const timings = {
			duration: 700,
			fill: 'forwards',
			// expo-out
			easing: 'cubic-bezier(0.16, 1.08, 0.38, 0.98)'
		}
		await to.animate(keyframes, timings).finished
		done()
	}
}
