import { lerp, map } from '@/utils/maths'
import { offset } from '@/utils/offset'

export default {
	name: 'patternGrid',
	component() {
		const container = this.$el.getElementsByClassName('pattern-grid-container')[0]
		const images = [...this.$el.getElementsByClassName('pattern-grid-image')] as HTMLElement[]
		const initialTranslations = [-250, -50, -200, -400]
		const initialTranslationsMobile = [-150, -50]
		const speeds = [2, 0.75, 1.45, 3]
		const speedsMobile = [0.75, 1.25]

		const translations = new Float32Array(images.length)
		images.forEach((_, index) => {
			translations[index] = 0
		})

		return {
			container,
			images,
			translations,
			columns: 2,
			initialTranslations,
			speeds,

			scrollRatio() {
				this.$useScrollRatio(
					this.container,
					ratio => {
						const _ratio = map(ratio, 1, -1, 0, 1)

						this.images.forEach((image, index) => {
							const modulo = index % this.columns
							const translation = this.initialTranslations[modulo] - _ratio * this.speeds[modulo] * 100
							this.translations[index] = lerp(this.translations[index], translation, 0.1)
							image.style.transform = `translateY(${this.translations[index]}px)`
						})
					},
					{
						offset: [
							[0, 0],
							[0, 1]
						]
					}
				)
			},

			setColumns(device) {
				this.columns = device === 'desktop' ? 4 : 2
				this.initialTranslations = device === 'desktop' ? initialTranslations : initialTranslationsMobile
				this.speeds = device === 'desktop' ? speeds : speedsMobile

				this.images.forEach((image, index) => {
					const modulo = index % this.columns
					image.style.transform = `translateY(${this.initialTranslations[modulo]}px)`
				})
			},

			init() {
				this.setColumns(this.$store.global.device)

				this.$watch('$store.global.device', device => {
					this.setColumns(device)
				})

				this.scrollRatio()
			}
		}
	}
}
