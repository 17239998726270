export default {
	name: 'newsletterModal',
	component() {
		const hideNewsletterModal = JSON.parse(sessionStorage.getItem('hideNewsletterModal') || 'false')
		return {
			modal: null,
			hideNewsletterModal,
			success: false,
			error: null as unknown as HTMLElement,
			subtitle: null as unknown as HTMLElement,
			form: null as unknown as HTMLElement,

			init() {
				this.$nextTick(() => {
					const newsletterModalEl = document.getElementById('newsletterModal')
					this.subtitle = this.modal.$refs.subtitle || (this.modal.$refs.subtitle as HTMLElement)
					this.error = this.modal.$refs.error || (this.modal.$refs.error as HTMLElement)
					this.form = this.modal.$refs.form as HTMLElement

					const obsever = new MutationObserver(mutations => {
						mutations.forEach(mutation => {
							if (mutation.type === 'attributes') {
								const submitSuccess = (mutation.target as HTMLElement).getAttribute('data-success')
								if (submitSuccess) {
									this.handleSuccess()
								}
							}
						})
					})
					if (this.form) {
						obsever.observe(this.form, { attributes: true })
					}

					if (!hideNewsletterModal && newsletterModalEl === this.modal.$el) {
						setTimeout(() => {
							this.modal.showModal = true
						}, 15000)
					}

					this.$watch('modal.showModal', showModal => {
						if (newsletterModalEl === this.modal.$el && !showModal) {
							sessionStorage.setItem('hideNewsletterModal', JSON.stringify(true))
						}
					})
				})
			},

			handleSuccess() {
				this.success = false

				this.resetModalText()

				this.success = true
				setTimeout(() => {
					this.modal.showModal = false
				}, 6000)
			},

			onError() {
				this.error?.classList.add('block')
				this.error?.classList.remove('hidden')
			},
			resetError() {
				this.error?.classList.remove('block')
				this.error?.classList.add('hidden')
			},

			onModalError() {
				this.onError()
				this.subtitle?.classList.remove('block')
				this.subtitle?.classList.add('hidden')
			},
			resetModalText() {
				this.resetError()
				this.subtitle?.classList.add('block')
				this.subtitle?.classList.remove('hidden')
			}
		}
	}
}
