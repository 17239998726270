export default {
	name: 'multiRangeSlider',
	component(config) {
		const _cbs = [...this.$el.querySelectorAll('.multirange-checkbox')]

		return {
			min: config.min,
			max: config.max,
			minVal: config.min,
			maxVal: config.max,
			minValCurrency: '',
			maxValCurrency: '',
			locale: config.locale,
			currencySymbol: config.currencySymbol,
			cbs: _cbs,
			formEl: null,

			setDefaultValues(min, max) {
				let _currentMin = min
				let _currentMax = max
				let _minIndex = -1
				let _maxIndex = -1

				if (this.cbs.length) {
					this.cbs.forEach((cb, index) => {
						if (cb.checked) {
							if (Number(cb.value) < _currentMin) {
								_currentMin = Number(cb.value)
								_minIndex = index
							}
							if (Number(cb.value) > _currentMax) {
								_currentMax = Number(cb.value)
								_maxIndex = index
							}
						}
					})

					if (_minIndex === _maxIndex) {
						if (_maxIndex === 0) {
							_currentMax = Number(this.cbs[_minIndex + 1].value)
						} else if (_minIndex === this.cbs.length - 1) {
							_currentMin = Number(this.cbs[_maxIndex - 1].value)
						}
					}

					// if (_currentMin === Infinity && _currentMax === -Infinity) {
					// 	_currentMin = config.min
					// 	_currentMax = config.max
					// }

					if (_currentMin === Infinity) {
						_currentMin = config.min
					}
					if (_currentMax === -Infinity) {
						_currentMax = config.max
					}
					if (_currentMin === this.min && _currentMax === this.max) {
						this.cbs.forEach((cb, index) => {
							cb.checked = false
						})
					}
				}
				if (_currentMin === Infinity) {
					_currentMin = config.min
				}
				if (_currentMax === -Infinity) {
					_currentMax = config.max
				}
				this.minVal = _currentMin
				this.maxVal = _currentMax
			},

			getPercent(value) {
				return Math.round(((value - this.min) / (this.max - this.min)) * 100)
			},

			updateMinVal(event) {
				const value = Math.min(Number(event.target.value), this.maxVal - 1)
				this.minVal = value
				this.updateRange()
			},

			updateMaxVal(event) {
				const value = Math.max(Number(event.target.value), this.minVal + 1)
				this.maxVal = value
				this.updateRange()
			},

			updateRange() {
				const minPercent = this.getPercent(this.minVal)
				const maxPercent = this.getPercent(this.maxVal)

				if (this.cbs.length) {
					this.cbs.forEach((cb, index) => {
						if (this.minVal === this.min && this.maxVal === this.max) {
							cb.checked = false
						} else {
							cb.checked = cb.value >= this.minVal && cb.value <= this.maxVal
						}
					})
				}

				if (this.$refs.range) {
					this.$refs.range.style.left = `${minPercent}%`
					this.$refs.range.style.width = `${maxPercent - minPercent}%`
				}

				this.minValCurrency = this.minVal.toLocaleString(this.locale, {
					style: 'currency',
					currency: this.currencySymbol
				})
				this.maxValCurrency = this.maxVal.toLocaleString(this.locale, {
					style: 'currency',
					currency: this.currencySymbol
				})
			},
			init() {
				const queryString = new URLSearchParams(window.location.search)
				const _min = this.$el.querySelector('input[data-min=""]')
				const _max = this.$el.querySelector('input[data-max=""]')
				_min.disabled = false
				_max.disabled = false

				this.formEl = this.$el.closest('form')
				this.formEl.addEventListener('submit', e => {
					_min.disabled = true
					_max.disabled = true
				})

				const currentMinQuery = queryString.get(_min.getAttribute('name'))
				const currentMaxQuery = queryString.get(_max.getAttribute('name'))
				const currentMin = currentMinQuery ? Number(currentMinQuery) : Infinity
				const currentMax = currentMaxQuery ? Number(currentMaxQuery) : -Infinity

				this.setDefaultValues(currentMin, currentMax)
				this.updateRange()
			}
		}
	}
}
