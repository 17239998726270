import alpine from '@/alpine'
import { map } from '@/utils/maths'

export default {
	name: 'shopTheScene',
	component() {
		return {
			child: {},
			wrapper: null as HTMLElement | null,
			x: 0,
			width: 0,
			device: this.$store.global.device,
			prevRatio: -Infinity,
			windowWidth: this.$store.global.viewport.innerWidth,

			init() {
				this.$nextTick(() => {
					this.wrapper = this.child.$refs.wrapper
					if (!this.wrapper) return

					this.$useWindowResize(({ innerWidth }) => {
						if (innerWidth >= 1024 && this.wrapper?.children?.length > 4) {
							this.$el.style.height = `${this.wrapper?.children?.length * 25 + 150}vh`
							this.device = 'desktop'
						} else {
							this.device = 'mobile'
							this.$el.style.height = ''
						}
						const wrapperBound = this.wrapper?.getBoundingClientRect() as DOMRect
						this.x = wrapperBound.left
						this.width = wrapperBound.width + innerWidth
					}, true)

					this.$useScrollRatio(this.$el, (ratio, { viewport }) => {
						const _ratio = map(ratio, -0.6, 0.5, 0, 1)

						if (!this.wrapper || this.prevRatio === ratio || this.wrapper.children.length <= 4) return

						if (this.device !== 'desktop') {
							this.wrapper.style.transform = `translate3d(0,0,0)`
							return
						}

						this.wrapper.style.transform = `translate3d(${-1 * (this.width - viewport.width) * _ratio + viewport.width}px,0,0)`

						this.prevRatio = ratio
					})
				})
			}
		}
	}
}
