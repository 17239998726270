export default {
	name: 'diptychDropdown',
	component() {
		return {
			currentIndex: 0,
			prevIndex: 0,

			getImageZindex(index: number) {
				if (index === this.currentIndex) {
					return 2
				} else if (index === this.prevIndex) {
					return 1
				} else {
					return 0
				}
			},

			handleAccordionClick(newIndex: number) {
				if (newIndex === this.currentIndex || this.$store.global.device !== 'desktop') return

				this.prevIndex = this.currentIndex
				this.currentIndex = newIndex
			}
		}
	}
}
