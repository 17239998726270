import { supportsTouchEvents } from 'detect-it'

import { clamp } from '@/utils/maths'

export default {
	name: 'storeLocatorSlideshowOld',
	component() {
		const groupBy = 1
		const wrapper = this.$refs.wrapper

		const prevButton = this.$refs.prevButton
		const nextButton = this.$refs.nextButton

		return {
			index: 0,
			prevIndex: -1,
			maxIndex: 0,
			x: 0,
			destX: 0,
			pages: 0,
			easing: 0.11,
			autoPlayEasing: 0.08,
			hasTouched: false,
			loop: false,
			buttonState: -1,
			interval: undefined,
			intersectionObserver: {} as IntersectionObserver,
			childrenIntersectionObserver: {} as IntersectionObserver,
			autoPlayStopped: false,
			containerBound: {} as DOMRect,
			bounds: [] as { left: number; width: number }[],
			children: [],
			windowWidth: window.innerWidth,

			handleTouchStart() {
				this.hasTouched = true
			},

			handlePrev() {
				const count = this.bounds.length
				const nextIndex = this.index >= groupBy ? this.index - groupBy : 0
				this.scrollTo(nextIndex)
			},

			handleNext() {
				const count = this.bounds.length
				const nextIndex = this.index < count - groupBy ? this.index + groupBy : count - groupBy

				this.scrollTo(nextIndex)
			},

			handleNavChange(index: number) {
				this.scrollTo(index)
			},

			scrollTo(index: number, instant = false) {
				if (!this.maxIndex || !this.containerBound) return

				this.hasTouched = false

				// let _index = index
				// const lowerBound = Math.floor(this.maxIndex / 3)
				// const upperBound = 2 * Math.floor(this.maxIndex / 3) + 1

				// // looping
				// // set to new bound index before the animated scroll happens
				// if (index < lowerBound) {
				// 	_index = upperBound
				// 	this.destX = Math.min(
				// 		this.bounds[upperBound].left,
				// 		this.bounds[this.bounds.length - 1].left + this.bounds[this.bounds.length - 1].width - this.containerBound.width
				// 	)
				// 	wrapper.scrollTo({ left: this.destX, behavior: 'instant' })
				// 	_index = this.prevIndex < lowerBound ? _index + 1 : _index - 1
				// } else if (index > upperBound) {
				// 	_index = lowerBound
				// 	this.destX = Math.min(
				// 		this.bounds[lowerBound].left,
				// 		this.bounds[this.bounds.length - 1].left + this.bounds[this.bounds.length - 1].width - this.containerBound.width
				// 	)
				// 	wrapper.scrollTo({ left: this.destX, behavior: 'instant' })
				// 	_index = this.prevIndex < upperBound ? _index - 1 : _index + 1
				// }
				// this.index = clamp(_index, 0, this.maxIndex)

				this.index = clamp(index, 0, this.maxIndex)
				const boundsIndex = index * groupBy
				this.destX = Math.min(
					this.bounds[boundsIndex].left,
					this.bounds[this.bounds.length - 1].left + this.bounds[this.bounds.length - 1].width - this.containerBound.width
				)

				if (wrapper && supportsTouchEvents) {
					wrapper.scrollTo({ left: this.destX, behavior: instant ? 'instant' : 'smooth' })
				} else if (wrapper) {
					wrapper.classList.remove('snap-x')
				}

				this.buttonState = this.index === 0 ? -1 : this.index >= this.maxIndex - 1 ? 1 : 0

				this.prevIndex = this.index
			},

			scrollToRatio(ratio: number) {
				if (!this.maxIndex || !this.containerBound) return

				this.hasTouched = false

				// Calculate the target index based on the ratio
				const targetIndex = Math.round(clamp(ratio, 0, 1) * this.maxIndex)

				this.index = clamp(targetIndex, 0, this.maxIndex)

				const boundsIndex = this.index * groupBy
				this.destX = Math.min(
					this.bounds[boundsIndex].left,
					this.bounds[this.bounds.length - 1].left + this.bounds[this.bounds.length - 1].width - this.containerBound.width
				)

				if (wrapper) {
					wrapper.scrollTo({ left: this.destX, behavior: 'smooth' })
					wrapper.classList.remove('snap-x')
				}

				this.buttonState = this.index === 0 ? -1 : this.index >= this.maxIndex - 1 ? 1 : 0
			},

			init() {
				this.children = wrapper.children

				if (prevButton) {
					prevButton.disabled = this.index === 0
				}

				const getItemsBoundingRect = () => {
					const windowWidth = window.innerWidth
					const observer = new IntersectionObserver(entries => {
						// we can't rely on this.containerBound.left in case the slideshow is in an animated container, such as a slideout
						if (this.containerBound?.left !== entries[0].left) {
							this.containerBound = wrapper.getBoundingClientRect()
						}

						this.bounds = entries
							.map(entry => {
								const size = {
									left: entry.boundingClientRect.left - this.x - this.containerBound.left + windowWidth / 2 - entry.boundingClientRect.width / 2,
									width: entry.boundingClientRect.width
								}
								console.log(size.left)
								return size
							})
							.sort(rect => rect.left)
						this.maxIndex = this.bounds.length - 1
						const delta = this.bounds[this.maxIndex].left + this.bounds[this.maxIndex].width - this.containerBound.width
						for (let i = this.maxIndex; i >= 0; i--) {
							const rect = this.bounds[i]
							if (rect.left - rect.width < delta) {
								this.maxIndex = i
								break
							}
						}

						observer.disconnect()
					})

					if (this.children) {
						Array.from(this.children).forEach((item, index) => {
							if (!item.className.match(/\b\w*hidden\w*\b/g)) {
								observer.observe(item)
							}
						})
					}

					if (prevButton && nextButton) {
						this.$watch('index', value => {
							if (prevButton && nextButton) {
								prevButton.disabled = value === 0

								nextButton.disabled = value >= this.maxIndex
							}
						})
					}
				}

				// update index based on intersection observer
				const handleChildEnter = (elements: IntersectionObserverEntry[]) => {
					const element = elements[0]

					if (element.isIntersecting && this.hasTouched) {
						const index = Math.floor(element.target.__i / groupBy)
						// console.log('index :', index)
						this.index = index
					}
				}

				this.childrenIntersectionObserver = new IntersectionObserver(handleChildEnter, {
					root: this.$el,
					threshold: 0.4
				})

				Array.from(this.children).forEach((el, index) => {
					if (el) {
						el.__i = index
						this.childrenIntersectionObserver.observe(el)
					}
				})

				this.$useRaf(() => {
					if (!supportsTouchEvents) {
						this.x += (this.destX - this.x) * this.easing

						if (wrapper) {
							wrapper.scrollTo({ left: this.x, behavior: 'instant' })
						}
					}

					// if (this.bounds.length && this.containerBound.width) {
					// 	if (this.hasTouched) {
					// 		wrapper.classList.remove('snap-x')
					// 	}
					// 	const scrollLeft = wrapper.scrollLeft
					// 	const lowerBound = Math.floor(this.maxIndex / 3)
					// 	const upperBound = 2 * Math.floor(this.maxIndex / 3) + 1

					// 	// console.log([
					// 	// 	scrollLeft,
					// 	// 	this.bounds[lowerBound].left - this.bounds[lowerBound].width / 3,
					// 	// 	this.bounds[upperBound].left - this.bounds[upperBound].width * 0.333
					// 	// ])

					// 	if (scrollLeft < this.bounds[lowerBound].left - this.bounds[lowerBound].width / 3) {
					// 		this.index = upperBound
					// 		const left = this.bounds[upperBound - 1].left + this.bounds[upperBound - 1].width / 2.5
					// 		console.log(left)

					// 		// wrapper.scrollTo({ left, behavior: 'instant' })
					// 		wrapper.scrollLeft = left
					// 	} else if (scrollLeft > this.bounds[upperBound].left + this.bounds[upperBound].width / 3) {
					// 		this.index = lowerBound
					// 		const left = this.bounds[lowerBound + 1].left - this.bounds[lowerBound + 1].width / 2.5
					// 		// console.log(left)
					// 		wrapper.scrollLeft = left
					// 		// wrapper.scrollTo({ left, behavior: 'instant' })
					// 		// wrapper.scrollTo({ left: this.bounds[lowerBound].left + this.bounds[lowerBound].width / 3, behavior: 'instant' })
					// 	}
					// }
				})

				// wrapper.addEventListener('scroll', () => {

				// })

				// update sizing when dom is displayed for the first time
				const handleEnter = (elements: IntersectionObserverEntry[]) => {
					const element = elements[0]
					if (element.isIntersecting) {
						if (this.intersectionObserver) this.intersectionObserver.disconnect()

						this.$useResize(wrapper, e => {
							this.containerBound = e.boundingClientRect || e.target.getBoundingClientRect()
							getItemsBoundingRect()
							// this.windowWidth = window.innerWidth
						})
					}
				}

				this.intersectionObserver = new IntersectionObserver(handleEnter, {})
				this.intersectionObserver.observe(this.$el)

				this.$watch('maxIndex', max => {
					// this.scrollTo(Math.floor(max / 3), true)
					// this.index = Math.floor(max / 3)
					const centerIndex = Math.floor(max / 2)
					this.scrollTo(centerIndex, true)
					this.index = centerIndex
				})
			},

			destroy() {
				clearInterval(this.interval)
				if (this.childrenIntersectionObserver) this.childrenIntersectionObserver.disconnect()
				if (this.intersectionObserver) this.intersectionObserver.disconnect()
			}
		}
	}
}
