export default {
	name: 'faqSearch',
	component() {
		const titleEls = Array.from(document.querySelectorAll('[data-title]'))
		const titles = titleEls.map(title => {
			const textContent = title.getAttribute('data-title')
			// Slugify the title
			const anchor = textContent?.toLowerCase().replace(/\s+/g, '-')
			const childrenCount = title.nextElementSibling?.children.length
			return { title: textContent, childrenCount, filteredCount: childrenCount + 1, anchor }
		})

		const accordionElements = Array.from(document.querySelectorAll('[data-parent-id]'))

		const entries = Array.from(document.querySelectorAll('[data-question]')).map((question, i) => {
			const textContent = question.textContent
			// const anchor = textContent?.toLowerCase().replace(/\s+/g, '-')
			const answer = question.getAttribute('data-answer')

			const parentElement = accordionElements[i]
			const parentElementId = parentElement.getAttribute('data-parent-id')
			const questionEl = parentElement.getElementsByClassName('question-text')[0]
			const answerEl = parentElement.getElementsByClassName('answer-text')[0]
			const checkbox = parentElement.querySelector('input[type="checkbox"]')

			return { question: textContent, answer, parentElement, parentElementId, questionEl, answerEl, checkbox }
		})

		const firstBlock = this.$el.nextElementSibling
		const parentNode = this.$el.parentNode
		if (firstBlock) {
			parentNode.insertBefore(firstBlock, this.$el)
		}

		return {
			titles,
			accordionElements,
			entries,
			search: '',

			searchTerm(term: string) {
				const filteredEntries = this.entries.filter(entry => {
					if (!term || term.length < 3) {
						entry.parentElement?.classList.remove('hidden')
						if (entry.questionEl) {
							entry.questionEl.innerHTML = entry.question || ''
						}
						if (entry.answerEl) {
							entry.answerEl.innerHTML = entry.answer || ''
						}
						if (entry.checkbox) {
							entry.checkbox.checked = false
						}
						titles.forEach((item, index) => {
							item.filteredCount = item.childrenCount + 1
							titleEls[index].classList.remove('hidden')
						})
						return
					}

					const _search = term.replace(/ /g, '').toLowerCase().trim()
					const question = entry?.question?.replace(/ /g, '').toLowerCase().trim() || ''
					const answer = entry?.answer?.replace(/ /g, '').toLowerCase().trim() || ''

					if (question.includes(_search) && entry.questionEl) {
						const text = entry.questionEl.innerText || ''
						const newText = text.replace(term, `<mark>${term}</mark>`)
						entry.questionEl.innerHTML = newText
					}
					if (answer.includes(_search) && entry.answerEl) {
						const text = entry.answerEl.innerText || ''
						const newText = text.replace(term, `<mark>${term}</mark>`)
						entry.answerEl.innerHTML = newText

						const checkbox = entry.parentElement?.querySelector('input[type="checkbox"]')
						if (checkbox) {
							checkbox.checked = true
						}
					}

					if (question.includes(_search) || answer.includes(_search)) {
						entry.parentElement?.classList.remove('hidden')
						// eslint-disable-next-line consistent-return
						return true
					} else {
						entry.parentElement?.classList.add('hidden')
						// eslint-disable-next-line consistent-return
						return false
					}
				})

				if (term.length >= 3) {
					titles.forEach((item, index) => {
						const arr = filteredEntries.filter(entry => entry.parentElementId === item.anchor)
						if (arr.length === 0) {
							titleEls[index].classList.add('hidden')
						} else {
							titleEls[index].classList.remove('hidden')
						}
					})
				}
			},

			init() {
				this.$watch('search', value => {
					this.searchTerm(value)
				})
			}
		}
	}
}

// use innerText
// function search(e) {
// 	let searched = document.getElementById('search').value.trim()
// 	if (searched !== '') {
// 		let text = document.getElementById('text').innerHTML
// 		let re = new RegExp(searched, 'g') // search for all instances
// 		let newText = text.replace(re, `<mark>${searched}</mark>`)
// 		document.getElementById('text').innerHTML = newText
// 	}
// }
