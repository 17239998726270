export default {
	name: 'slideout',
	component({ openOnHash, hash } = { openOnHash: false, hash: null }) {
		return {
			isOpened: false,

			init() {
				this.$watch('isOpened', shouldLock => {
					// FIX: it's seems that it get triggered on page load ? (with `shouldLock === undefined`)
					// causing `$store.global.bodyLocksCount === -1` and scroll wasn't unlocking
					if (shouldLock !== undefined) {
						this.$store.global.bodyScrollLock(shouldLock, openOnHash && hash && window.location.hash === hash)
					}
				})

				this.$nextTick(() => {
					window?._app?.taxi?.on('NAVIGATE_OUT', () => {
						this.isOpened = false
						this.$dispatch('toggle', this.isOpened)
					})
				})

				if (openOnHash && Boolean(hash) && window.location.hash === hash) {
					this.isOpened = true
					this.$nextTick(() => {
						this.$dispatch('toggle', true)
					})
				}
			},

			handleEffect(openVar) {
				this.isOpened = openVar
			}
		}
	}
}
