// Before implementing this, you'll need to turn off Google's ReCaptcha
// for your Shopify store's contact forms. Otherwise, it will redirect to the captcha's verification page.
// This can be turned on when going live

import { formDataToObject } from '@/utils/object'

// How this works:
// 1. Get all forms on a page that match `form[data-type='ajax']` selector
// 2. For each form, initialize a AjaxForm object
// 3. AjaxForm handles the submit event of a form, and calls .preventDefault() on it
// 4. The FormData is POSTed to Shopify, and the response HTML is parsed to fetch any errors for this form
// 5. If any errors were found, we inject it back into the DOM to make it visible

interface AjaxFormProps {
	form: HTMLFormElement
}

const FORM_SELECTOR = "form[data-type='ajax']"

const KLAVIYO_COMPANY_ID = 'VVwdpp'

// Manages logic for a single form
class AjaxForm {
	form: HTMLFormElement
	action?: string
	returnTo?: string
	successMessage?: string
	listOnChange: boolean
	withNewsletter: boolean
	onlyNewsletter: boolean
	state: { loading: boolean }
	dom: {
		inputs: HTMLInputElement[]
		errors: Element | null
	}

	constructor({ form }: AjaxFormProps) {
		this.form = form
		this.dom = {
			inputs: Array.from(form.querySelectorAll('input[name]')),
			errors: form.querySelector('[data-form-status]')
		}
		this.state = {
			loading: false
		}
		this.action = this.form.getAttribute('action') || undefined
		this.returnTo = this.form.getAttribute('data-return-to') || undefined
		this.successMessage = this.form.getAttribute('data-success-message') || undefined
		this.listOnChange = Boolean(this.form.getAttribute('data-list-on-change')) || false
		this.onlyNewsletter = Boolean(this.form.getAttribute('data-only-newsletter')) || false
		this.withNewsletter = Boolean(this.form.getAttribute('data-with-newsletter')) || false

		if (this.action) {
			this.listen()
		} else {
			console.warn('[form]: Missing form action:', form)
		}
	}

	listen = () => {
		this.form.addEventListener('submit', this.handleSubmit)
	}

	handleSubmit = async e => {
		e.preventDefault()
		e.stopPropagation()

		try {
			// abort if request if already in progress
			if (this.state.loading) return
			this.state.loading = true

			const formData = new FormData(this.form)

			const promises: Promise<Response>[] = []
			if (!this.onlyNewsletter) {
				const formFetch = fetch(this.action!, {
					method: 'POST',
					body: formData
				})
				promises.push(formFetch)
			}

			if (this.onlyNewsletter || this.withNewsletter) {
				const newsletterFetch = this.getNewsletterFetch(formData)
				promises.push(newsletterFetch)
			}

			const responses = await Promise.all(promises)

			if (!this.onlyNewsletter && this.withNewsletter) {
				const [response, newsletterResponse] = responses
				// console.log('response :', response)
				// console.log(newsletterResponse)
				if (response.status === 200 && newsletterResponse.status === 202) {
					this.handleSuccess(response)
					this.form.setAttribute('data-success', 'true')
				} else {
					throw new Error('Form submission failed.')
				}
			} else if (!this.onlyNewsletter && !this.withNewsletter) {
				const [response] = responses
				if (response.status === 200) {
					this.handleSuccess(response)
					this.form.setAttribute('data-success', 'true')
				} else {
					throw new Error('Form submission failed.')
				}
			} else if (this.onlyNewsletter) {
				const [newsletterResponse] = await Promise.all(promises)
				if (newsletterResponse.status === 202) {
					// this.handleSuccess(response)
					this.form.setAttribute('data-success', 'true')
				} else {
					throw new Error('Form submission failed.')
				}
			}
		} catch (err) {
			this.handleError(err)
		} finally {
			this.state.loading = false
		}
	}

	handleSuccess = async (response: Response) => {
		const res = await response.text()
		const parser = new DOMParser()
		const doc = parser.parseFromString(res, 'text/html')
		const updatedFormEl = doc.querySelector(`form[action='${this.action}']`)
		if (updatedFormEl) {
			const possibleErrors = updatedFormEl.querySelector(`[data-errors]`)
			if (this.dom.errors && possibleErrors) {
				this.dom.errors!.innerHTML = possibleErrors!.innerHTML
				console.log(`[form:]:error:`, 'injected errors')
			} else {
				this.form.setAttribute('data-success', 'true')
				console.log(`[form:]:handleSuccess:`, 'success')
				if (this.returnTo) {
					window._app.taxi.navigateTo(this.returnTo)
				}
				if (this.successMessage) {
					this.dom.errors!.innerHTML = this.successMessage
				}
			}
		} else {
			console.log(`[form:]:handleSuccess:`, 'cannot find updated form el', doc)
			this.form.setAttribute('data-success', 'true')

			if (this.returnTo) {
				window._app.taxi.navigateTo(this.returnTo)
			}
		}
	}

	handleError = err => {
		console.log(`[form:]:err:`, err)
	}

	destroy = () => {
		this.form.removeEventListener('submit', this.handleSubmit)
	}

	getNewsletterFetch = (formData: FormData): Promise<Response> | void => {
		const { locale } = window.Shopify
		const newsletterId = locale === 'fr' ? window.newsletterIDs.fr : window.newsletterIDs.en
		console.log('newsletterId :', newsletterId)

		const data = formDataToObject(formData)

		const acceptsMarketing = data['customer[accepts_marketing]']
		const email = data['customer[email]'] || (data['contact[email]'] as string)
		if (acceptsMarketing === 'false' || !email) {
			return Promise.resolve()
		}

		const headers = new Headers()
		headers.append('content-type', 'application/json')
		headers.append('accept', 'application/json')
		headers.append('revision', '2024-07-15')

		return fetch(`https://a.klaviyo.com/client/subscriptions/?company_id=${KLAVIYO_COMPANY_ID}`, {
			method: 'POST',
			headers,
			body: JSON.stringify({
				data: {
					type: 'subscription',
					attributes: {
						profile: {
							data: {
								type: 'profile',
								attributes: {
									email
								}
							}
						}
					},
					relationships: {
						list: {
							data: {
								type: 'list',
								id: newsletterId
							}
						}
					}
				}
			})
		})
	}
}

// Manages logic for all forms in a page
class AjaxFormManager {
	forms: AjaxForm[]

	constructor() {
		this.forms = []
	}

	init = () => {
		this.forms = Array.from(document.querySelectorAll(FORM_SELECTOR)).map(el => new AjaxForm({ form: el as HTMLFormElement }))
	}

	destroy = () => {
		this.forms.forEach(form => form.destroy())
		this.forms = []
	}
}

// expose a singleton which will manage all forms
export const globalFormManager = new AjaxFormManager()
