export default {
	name: 'couchConfiguratorPopin',
	component() {
		return {
			isVisible: false,

			init() {
				window._lenis.on('scroll', this.handleScroll.bind(this))
			},
			handleScroll() {
				const scrollPosition = window._lenis?.actualScroll || 0
				const { vh } = this.$store.global.viewport

				this.isVisible = scrollPosition > vh
			}
		}
	}
}
