import { Renderer } from '@unseenco/taxi'

export class BaseRenderer extends Renderer {
	isInitialLoad?: boolean

	initialLoad() {
		this.isInitialLoad = true
		this.onEnter()
		this.onEnterCompleted()
		this.isInitialLoad = false
	}

	onEnter() {
		window._lenis?.scrollTo(0, { immediate: true, force: true })

		// should not fire on initialLoad
		if (!this.isInitialLoad) {
			this.remove()
		}
	}
}
