import { formDataToObject } from '@/utils/object'

const KLAVIYO_COMPANY_ID = 'VVwdpp'
const KLAVIYO_LIST_FR_ID = window.newsletterIDs.fr
const KLAVIYO_LIST_EN_ID = window.newsletterIDs.en

export default {
	name: 'newsletterForm',
	component() {
		return {
			form: null as unknown as HTMLFormElement,
			success: false,
			error: null as unknown as HTMLElement,
			subtitle: null as unknown as HTMLElement,

			init() {},

			async handleSubmit(e) {
				e.preventDefault()
				this.success = false
				this.resetError()

				const { locale } = window.Shopify
				const newsletterId = locale === 'fr' ? KLAVIYO_LIST_FR_ID : KLAVIYO_LIST_EN_ID
				console.log('newsletterId :', newsletterId)

				const formData = new FormData(e.target)
				const data = formDataToObject(formData)

				const acceptsMarketing = data['customer[accepts_marketing]']
				if (acceptsMarketing === 'false') {
					return
				}

				const email = data['customer[email]'] as string

				const headers = new Headers()
				headers.append('content-type', 'application/json')
				headers.append('accept', 'application/json')
				headers.append('revision', '2024-07-15')

				try {
					const res = await fetch(`https://a.klaviyo.com/client/subscriptions/?company_id=${KLAVIYO_COMPANY_ID}`, {
						method: 'POST',
						headers,
						body: JSON.stringify({
							data: {
								type: 'subscription',
								attributes: {
									profile: {
										data: {
											type: 'profile',
											attributes: {
												email
											}
										}
									}
								},
								relationships: {
									list: {
										data: {
											type: 'list',
											id: newsletterId
										}
									}
								}
							}
						})
					})
					console.log('res :', res)
					if (res.status === 202) {
						this.success = true
						setTimeout(() => {
							if (!withRegistration) {
								this.form.reset()
							}
							if (withModal) {
								this.modal.showModal = false
							}
						}, 6000)
					} else {
						throw new Error('something_went_wrong')
					}
				} catch (error) {
					console.log(error)
					if (withModal) {
						this.onModalError()
					} else if (withError) {
						this.onError()
					}
				}
			},

			onError() {
				this.error?.classList.add('block')
				this.error?.classList.remove('hidden')
			},
			resetError() {
				this.error?.classList.remove('block')
				this.error?.classList.add('hidden')
			},

			onModalError() {
				this.onError()
				this.subtitle?.classList.remove('block')
				this.subtitle?.classList.add('hidden')
			},
			resetModalText() {
				this.resetError()
				this.subtitle?.classList.add('block')
				this.subtitle?.classList.remove('hidden')
			}
		}
	}
}
