import { clamp } from '@/utils/maths'

export default {
	name: 'horizontalDiptych',
	component(config) {
		return {
			init() {
				const container = this.$refs.container

				this.$useScrollRatio(
					container,
					ratio => {
						if (this.$store.global.device === 'desktop') {
							const r = clamp(ratio, 0, 1)
							container.style.transform = `translate3d(calc(${-r} * (100% - var(--screenWidth))),0,0)`
						} else {
							container.style.transform = ''
						}
					},
					{
						offset: [
							[0, 0],
							[1, 1]
						]
					}
				)
			}
		}
	}
}
