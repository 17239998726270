import { devices } from '@/utils/devices'

export default {
	name: 'global',
	store() {
		return {
			isMinicartVisible: false,
			isColorListVisible: false,
			isSearchVisible: false,
			device: devices[0].name,
			viewport: { vw: window.innerWidth, vh: window.innerHeight },
			cart: undefined as any,
			hasBodyLock: false,
			bodyLocksCount: 0,
			savedScrollY: window.scrollY,
			isCouchConfiguratorVisible: false,

			init() {
				this.initLiquidAJaxCart()

				this.setDevice()
				window.addEventListener('resize', this.setDevice.bind(this), { passive: true })

				document.addEventListener('deviceready', () => {
					window.navigation?.addEventListener('navigate', () => {
						this.bodyScrollLock(false, true)
						this.bodyLocksCount = 0
					})
				})
			},
			initLiquidAJaxCart() {
				document.addEventListener('liquid-ajax-cart:request-end', event => {
					const { requestState, cart } = event.detail

					if (requestState.requestType === 'add') {
						if (requestState.responseData?.ok) {
							this.isMinicartVisible = true
						}
					}

					this.cart = cart
				})
			},
			setDevice() {
				this.viewport.vw = window.innerWidth
				this.viewport.vh = window.innerHeight
				const currentDevice = devices.findLast(d => d.breakpoint < this.viewport.vw)?.name || devices[0].name
				this.device = currentDevice
			},
			toggleColorList() {
				this.isColorListVisible = !this.isColorListVisible
			},
			bodyScrollLock(shouldLock, force: boolean) {
				if (shouldLock) {
					if (!this.hasBodyLock || force) {
						this.savedScrollY = window._lenis.targetScroll
						window._lenis.destroy()

						document.body.style.position = 'fixed'
						document.body.style.top = `-${this.savedScrollY}px`
					}
					this.bodyLocksCount += 1
					this.hasBodyLock = shouldLock
				} else {
					this.bodyLocksCount -= 1

					if ((this.hasBodyLock && this.bodyLocksCount <= 0) || force) {
						document.body.style.removeProperty('position')
						document.body.style.removeProperty('top')

						window._getLenis()
						window._lenis.scrollTo(this.savedScrollY, { immediate: true, force: true })

						this.hasBodyLock = shouldLock
					}
				}
			}
		}
	}
}
