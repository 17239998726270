export default {
	name: 'plpFilterPanel',
	component() {
		return {
			init() {
				const formEl = document.getElementById('collection-header-form')
				const header = document.getElementById('header')
				if (formEl) {
					this.$watch('$store.isFilterOpen', value => {
						if (value) {
							formEl.style.zIndex = '11'
							header!.style.zIndex = '10'
						} else {
							setTimeout(() => {
								formEl.style.zIndex = ''
								header!.style.zIndex = ''
							}, 600)
						}
					})
				}

				const sortBy = new URLSearchParams(window.location.search).get('sort_by')
				if (sortBy) {
					const radio = this.$el.querySelector(`input[name="sort_by"][value="${sortBy}"]`)
					if (radio) {
						radio.checked = true
					}
				}
			}
		}
	}
}
