import debounce from 'debounce'

import { lerp } from '@/utils/maths'

export default {
	name: 'fabricSlideshow',
	component(config) {
		return {
			count: config.count,
			currentIndex: -1,
			slideshowWrapper: {} as HTMLElement,
			slides: [] as HTMLElement[],
			images: [] as HTMLImageElement[],
			links: [] as HTMLAnchorElement[],
			linksMobile: [] as HTMLAnchorElement[],
			content: [] as HTMLDivElement[],
			ratios: new Float32Array(config.count),
			translations: new Float32Array(config.count),
			slideshowOffset: 0,
			cardRect: {} as DOMRect,
			toRatio: 50 / 225,
			padding: 25,
			smallCardRatio: 0.25,
			destX: 0,
			isHovering: false,
			slideIndex: -1,

			handleEnter(index) {
				if (this.$store.global.device === 'desktop') {
					this.isHovering = true
					this.currentIndex = index
				}
			},

			handleLeave(e) {
				if (this.$store.global.device === 'desktop') {
					this.isHovering = false
					setTimeout(() => {
						if (!this.isHovering) {
							this.currentIndex = -1
						}
					}, 150)
				}
			},

			handleNext() {
				if (this.slideIndex < this.count - 1) {
					this.currentIndex++
					this.slideIndex++
					if (this.slideIndex > 4 && this.slideIndex < this.count - 4) {
						this.destX = this.cardRect.width * this.smallCardRatio * (this.slideIndex - 4)
					} else if (this.slideIndex <= 4) {
						this.destX = 0
					}
					this.$refs.slideshow.scrollTo({ left: this.destX, behavior: 'smooth' })
				}
			},

			handlePrev() {
				if (this.slideIndex > 0) {
					this.currentIndex--
					this.slideIndex--

					if (this.slideIndex > 4 && this.slideIndex < this.count - 4) {
						this.destX = this.slideIndex <= 0 ? 0 : this.cardRect.width * this.smallCardRatio * (this.slideIndex - 4)
					} else if (this.slideIndex <= 4) {
						this.destX = 0
					}
					this.$refs.slideshow.scrollTo({ left: this.destX, behavior: 'smooth' })
				}
			},

			handleClick(index) {
				this.currentIndex = index
				this.slideIndex = index
			},

			setSizes(device) {
				this.padding = device === 'desktop' ? 25 : 15
				this.toRatio = device === 'desktop' ? 50 / 225 : 30 / 185
				this.smallCardRatio = device === 'desktop' ? 0.25 : 0.2

				this.slides[0].style.transform = `none`
				this.cardRect = this.slides[0]?.getBoundingClientRect()
				const cardsWidth = (this.cardRect.width * 0.1 * this.count) / 2
				this.slides.forEach((_, index) => {
					this.ratios[index] = 0
					this.translations[index] = this.cardRect.width * this.smallCardRatio * index
					if (device === 'desktop' && this.count < 24) {
						this.translations[index] += cardsWidth
					}
				})

				// set slideshow width
				if (this.$store.global.device === 'desktop') {
					if (this.count >= 24) {
						this.slideshowWrapper.style.width = `calc((var(--column) + var(--gutter)) * ${4 * this.smallCardRatio * this.count} + 2 * var(--margin))`
					} else {
						this.slideshowWrapper.style.width = '100%'
					}
				} else {
					this.slideshowWrapper.style.width = `calc((var(--column) + var(--gutter)) * ${6 * this.smallCardRatio * this.count} + 2 * var(--margin))`
				}
			},

			runRaf() {
				const velocity = 0.1

				this.$useRaf(() => {
					if (this.ratios.length === 0) return

					this.ratios.forEach((ratio, index) => {
						const destRatio = index === this.currentIndex ? 1 : 0

						this.ratios[index] = lerp(this.ratios[index], destRatio, velocity)

						if (this.ratios[index] < 0.001) this.ratios[index] = 0
						if (this.ratios[index] > 0.999) this.ratios[index] = 1

						const slide = this.slides[index]

						const scale = this.toRatio + (1 - this.toRatio) * this.ratios[index]
						const inverseScale = 1 / scale
						let translateX = 0
						translateX += this.cardRect.width * this.smallCardRatio * index - (this.cardRect.width - this.padding * 2) / 2 - this.slideshowOffset

						if (this.$store.global.device === 'desktop' && this.count < 24) {
							translateX += (this.cardRect.width * 0.145 * this.count) / 2
						}

						// don't transition the first 3 items to the left, when we are hover the first 3 items
						if (this.currentIndex < 4 && this.currentIndex !== -1) {
							if (this.currentIndex === index) {
								translateX += this.cardRect.width / 2 - this.padding
							} else if (index > this.currentIndex) {
								translateX += this.cardRect.width - this.padding * 2
							}
							// don't transition the last 3 items to the right, when we are hover the last 3 items
						} else if (this.currentIndex > this.count - 1 - 4 && this.currentIndex !== -1) {
							if (this.currentIndex === index) {
								translateX -= this.cardRect.width / 2 - this.padding
							} else if (index < this.currentIndex) {
								translateX -= this.cardRect.width - this.padding * 2
							}
							// tranlate items to the left and right side
						} else {
							// right items
							if (index > this.currentIndex && this.currentIndex !== -1) {
								translateX += this.cardRect.width / 2 - this.padding
							}
							// left items
							if (index < this.currentIndex && this.currentIndex !== -1) {
								translateX -= this.cardRect.width / 2 - this.padding
							}
						}

						this.translations[index] = lerp(this.translations[index], translateX, velocity)

						slide.style.transform = `translate3d(${this.translations[index]}px,0,0) scale3d(${scale},1,1)`

						this.images[index].style.transform = `scale3d(${inverseScale},1,1)`
						if (this.$store.global.device === 'desktop') {
							this.links[index].style.transform = `translate3d(-50%,-50%,0) scale3d(${inverseScale},1,1)`
							this.links[index].style.opacity = `${this.ratios[index]}`
							this.links[index].style.visibility = this.ratios[index] > 0 ? 'visible' : 'hidden'
						} else {
							this.linksMobile[index].style.transform = `translate3d(-50%,-50%,0) scale3d(${inverseScale},1,1)`
							this.linksMobile[index].style.opacity = `${this.ratios[index]}`
							this.linksMobile[index].style.visibility = this.ratios[index] > 0 ? 'visible' : 'hidden'
						}

						this.content[index].style.transform = `scale3d(${inverseScale},1,1)`
						this.content[index].style.opacity = `${this.ratios[index]}`
						this.content[index].style.visibility = this.ratios[index] > 0 ? 'visible' : 'hidden'
					})
				})
			},

			init() {
				this.slides = [...this.$el.getElementsByClassName('fabric-card')] as HTMLElement[]
				this.images = [...this.$el.getElementsByClassName('fabric-picture')] as HTMLImageElement[]
				this.links = [...this.$el.getElementsByClassName('fabric-link')] as HTMLAnchorElement[]
				this.linksMobile = [...this.$el.getElementsByClassName('fabric-link-mobile')] as HTMLAnchorElement[]
				this.content = [...this.$el.getElementsByClassName('fabric-content')] as HTMLImageElement[]

				this.slideshowWrapper = this.$refs.wrapper

				this.setSizes(this.$store.global.device)
				window.addEventListener(
					'resize',
					debounce(() => {
						this.setSizes(this.$store.global.device)
					}, 400)
				)

				this.$watch('$store.global.device', device => {
					this.setSizes(device)
				})

				this.runRaf()
			}
		}
	}
}
