import { lerp } from '@/utils/maths'

export default {
	name: 'marqueeScroll',
	component(config = { speed: 1 }) {
		const { speed } = config
		const wrappers = [...this.$el.getElementsByClassName('wrapper')]

		return {
			x: 0,
			wrappers,
			inView: false,
			intersectionObserver: undefined as IntersectionObserver | undefined,
			wrapperBounds: {} as DOMRect,
			windowWidth: window.innerWidth,
			velocity: 0,
			prevScrollY: 0,

			init() {
				this.intersectionObserver = new IntersectionObserver(entries => {
					entries.forEach(entry => {
						this.inView = entry.isIntersecting
					})
				})
				this.intersectionObserver.observe(this.$el)

				this.$useResize(wrappers[0], ({ boundingClientRect }) => {
					this.wrapperBounds = boundingClientRect
					this.windowWidth = window.innerWidth

					this.wrappers.forEach(wrapper => {
						wrapper.style.transform = `translateX(${-this.wrapperBounds.width}px)`
					})
				})

				this.$useScrollRatio(this.$el, (ratio, { scrollY }) => {
					const scrollVelocity = Math.abs(scrollY - this.prevScrollY) * 0.4

					this.prevScrollY = scrollY
					this.velocity = lerp(this.velocity, scrollVelocity, 0.07)

					if (this.inView) {
						if (Math.abs(this.x) >= this.wrapperBounds.width) {
							this.x = 0
						}
						this.x -= speed
						this.x -= this.velocity
						this.wrappers.forEach(wrapper => {
							wrapper.style.transform = `translate3d(${this.x}px,0,0)`
						})
					}
				})
			},

			destroy() {
				this.intersectionObserver?.disconnect()
			}
		}
	}
}
