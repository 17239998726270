export default {
	name: 'selectCustom',
	component() {
		let _data = this.$el.dataset.data
		_data = JSON.parse(_data)
		let _limitedData = this.$el.dataset.limitedData
		_limitedData = JSON.parse(_limitedData)

		const _name = this.$el.dataset.name
		const _placeholder = this.$el.dataset.placeholder
		const _defaultValue = this.$el.dataset.defaultValue
		const _variantIds = JSON.parse(this.$el.dataset.variantIds)

		return {
			data: _data,
			limitedData: _limitedData,
			focusedOptionIndex: -Infinity,
			name: _name,
			open: false,
			options: {},
			placeholder: _placeholder ?? 'Select an option',
			value: _defaultValue || Object.values(_data)[0],
			variantIds: _variantIds,

			closeListbox() {
				this.open = false

				this.focusedOptionIndex = -Infinity
			},

			focusNextOption() {
				if (this.focusedOptionIndex === -Infinity) {
					this.focusedOptionIndex = Object.keys(this.options).length - 1
					return
				}

				if (this.focusedOptionIndex + 1 >= Object.keys(this.options).length) {
					return
				}

				this.focusedOptionIndex++

				this.$refs.listbox.children[this.focusedOptionIndex].scrollIntoView({
					block: 'center'
				})
			},

			focusPreviousOption() {
				if (this.focusedOptionIndex === -Infinity) {
					this.focusedOptionIndex = 0
					return
				}

				if (this.focusedOptionIndex <= 0) {
					return
				}

				this.focusedOptionIndex--

				this.$refs.listbox.children[this.focusedOptionIndex].scrollIntoView({
					block: 'center'
				})
			},

			init() {
				this.options = this.limitedData

				if (!(this.value in this.options)) {
					this.value = null
				}

				this.$nextTick(() => {
					this.$watch('$parent.selectedColor', newParentValue => {
						this.value = newParentValue
					})
				})
			},

			selectOption(e) {
				if (!this.open) {
					this.toggleListboxVisibility()
					return
				}
				this.value = Object.keys(this.options)[this.focusedOptionIndex]
				this.$parent.selectedColor = this.value
				this.closeListbox()
			},

			toggleListboxVisibility() {
				if (this.open) {
					this.closeListbox()
					return
				}

				this.focusedOptionIndex = Object.keys(this.options).indexOf(this.value)

				if (this.focusedOptionIndex < 0) {
					this.focusedOptionIndex = 0
				}

				this.open = true
			}
		}
	}
}
