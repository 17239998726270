export default {
	name: 'fullscreenSlideshow',
	component() {
		const images = [...this.$el.getElementsByClassName('bg-image')] as HTMLImageElement[]
		return {
			index: 0,
			prevIndex: 0,
			images,
			child: null,

			init() {
				this.images[this.index].classList.add('active')

				this.$watch('child?.index', newIndex => {
					this.prevIndex = this.index
					this.index = parseInt(newIndex, 10)

					this.images.forEach((image, i) => {
						if (i === newIndex) {
							image.classList.add('active')
							image.style.zIndex = '2'
						} else if (i === this.prevIndex) {
							image.classList.remove('active')
							image.style.zIndex = '1'
						} else {
							image.style.zIndex = '0'
						}
					})
				})

				// reset slideshow on device change
				this.$watch('$store.global.device', () => {
					this.images.forEach((image, i) => {
						image.classList.remove('active')
						image.style.zIndex = '0'
					})
					this.images[0].classList.add('active')
					this.child.index = 0
				})
			}
		}
	}
}
