import { offsetLeft } from '@/utils/offset'

export default {
	name: 'accordion',
	component(props = { defaultOpen: null, hasCheckContent: false }) {
		return {
			selected: props.defaultOpen,
			hasContentBelow: false,
			hasCheckContent: props.hasCheckContent,

			handleChange(e) {
				const { id, checked } = e.target
				this.selected = checked ? id : null
			},

			handleClick(e) {
				const { id } = e.target
				this.selected = this.selected === id ? null : id
			},

			init() {
				// mainly used in template-collection to show/hide the product type pills
				if (this.hasCheckContent) {
					this.$useResizeObserver(this.$el, () => {
						this.hasContentBelow = false
						let smallestLeft = Infinity

						Array.from(this.$refs.content.children).forEach((child, index) => {
							if (this.hasContentBelow) return

							const childLeft = offsetLeft(child)
							if (childLeft < smallestLeft) {
								smallestLeft = childLeft
							}

							if (smallestLeft === childLeft && index !== 0) {
								this.hasContentBelow = true
							}
						})
					})
				}
			}
		}
	}
}
