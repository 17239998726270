const resizeObserver: { current: ResizeObserver | null } = { current: null }
const cbs: { ref: HTMLElement; callback }[] = []

export default {
	name: 'useResizeObserver',
	callback: (el, { cleanup }) => {
		return (ref, callback) => {
			if (!resizeObserver.current) {
				resizeObserver.current = new ResizeObserver(entries => {
					cbs.forEach(({ ref, callback }) => {
						const entry = entries.find(e => {
							return ref === e.target
						})
						if (entry) callback(entry)
					})
				})
			}

			resizeObserver.current.observe(ref)
			cbs.push({ ref, callback })

			cleanup(() => {
				if (resizeObserver.current) {
					resizeObserver.current.unobserve(ref)
					const index = cbs.findIndex(e => e.callback === callback)
					if (index !== -1) {
						cbs.splice(index, 1)
						if (cbs.length === 0) {
							resizeObserver.current.disconnect()
							resizeObserver.current = null
						}
					}
				}
			})
		}
	}
}
