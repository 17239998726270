export default {
	name: 'accountRegister',
	component() {
		return {
			async submit(e) {
				e.preventDefault()

				try {
					const response = await fetch(this.$el.action, {
						method: 'GET',
						body: new FormData(this.$el)
					})
					console.log('response :', response.statusText)

					// const arr = await response.arrayBuffer()
					// console.log('arr :', arr)
					const text = await response.text()
					console.log('text :', text)
					// const json = await response.json()
					// console.log('json :', json)
				} catch (error) {
					console.error('Error:', error)
				}
			},

			init() {
				this.$el.addEventListener('submit', this.submit.bind(this))
			}
		}
	}
}
