import { currencySymbols, shopifyFormatCurrency } from '@/utils/money'

export default {
	name: 'slideoutProductCard',
	component(config) {
		return {
			productId: config.productId,
			sectionId: config.sectionId,
			productHandle: config.productHandle,
			variantId: config.variantId,
			isAvailable: config.isAvailable,
			variants: [],
			product: null,
			quantity: 1,
			productImage: null as HTMLImageElement | null,
			price: '',

			handleClick() {
				window.liquidAjaxCart.add({ items: [{ id: this.variantId, quantity: this.quantity }] })
			},

			closePanel() {
				// closes slideout panel
				this.$parent.isOpen = false
			},

			setVariant() {
				if (!this.product) return

				const variant = this.product.variants.find(variant => {
					let isNewVariantMatch = true
					this.variants.forEach((select, index) => {
						const [selectOptionValue, selectOptionId] = select.value.split('___')
						const variantOptionValue = variant.options[index]
						if (selectOptionValue !== variantOptionValue) {
							isNewVariantMatch = false
						}
					})
					return isNewVariantMatch
				})
				if (variant) {
					this.variantId = variant.id
					this.isAvailable = variant.available
					this.price = `${currencySymbols[window.Shopify.currency.active]}${shopifyFormatCurrency(variant.price, '{{ amount_with_comma_separator }}')}`

					// update productImage
					if (this.productImage) {
						const [productImageUrl, productImageParams] = this.productImage.src.split('?')
						let newUrl = variant.featured_image.src || productImageUrl
						newUrl += `&${productImageParams}`

						const srcset = this.productImage.srcset
							.split(',')
							.map(src => {
								const [srcUrl, srcWidth] = src.trim().split(' ')
								const [, srcUrlParams] = srcUrl.split('?')
								return `${variant.featured_image.src}&${srcUrlParams} ${srcWidth}`
							})
							.join(', ')

						this.productImage.src = newUrl
						this.productImage.srcset = srcset
					}
				}
			},

			updateProduct() {
				if (this.product) {
					this.setVariant()
				} else {
					const url = `/products/${this.productHandle}.js`
					fetch(url)
						.then(response => response.json())
						.then(product => {
							this.product = product
							this.setVariant()
						})
						.catch(error => {
							console.log(error)
						})
				}
			},

			handleQuantity({ target: quantitySelect }) {
				this.quantity = parseInt(quantitySelect?.value || 1, 10)
			},

			init() {
				this.productImage = this.$el.querySelector('.product-card-image')
			}
		}
	}
}
