export default {
	name: 'videoPlayerYoutube',
	component(props = { url: '' }) {
		const { url } = props

		const ytId = url?.split('v=')[1]

		const playerRef = { current: undefined, focusTo: this.$el.querySelector('.v-playPauseButton') }

		return {
			player: playerRef.current,
			intersectionObserver: undefined,
			ytId,
			wasOpended: false,

			async initPlayer() {
				const [{ default: Vlitejs }, { default: VlitejsYoutube }] = await Promise.all([import('vlitejs'), import('vlitejs/providers/youtube')])

				Vlitejs.registerProvider('youtube', VlitejsYoutube)

				const instance = new Vlitejs(this.$el, {
					options: {
						controls: true,
						autoplay: true,
						playPause: true,
						progressBar: true,
						time: true,
						volume: true,
						fullscreen: true,
						bigPlay: false,
						playsinline: true,
						loop: false,
						muted: false,
						autoHide: false
					},
					provider: 'youtube'
					// onReady(player) {
					// 	console.log(player)
					// }
				})
				this.player = instance.player
			},

			init() {
				this.$nextTick(() => {
					this.$watch('$parent.showModal', showModal => {
						if (showModal && !this.wasOpended) {
							this.wasOpended = true
							this.initPlayer()
						}
					})
				})
			}
		}
	}
}
