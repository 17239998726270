import { formDataToObject } from '@/utils/object'

const KLAVIYO_COMPANY_ID = 'VVwdpp'

export default {
	name: 'outOfStockModal',
	component() {
		return {
			modal: null,
			success: false,
			error: null as unknown as HTMLElement,
			subtitle: null as unknown as HTMLElement,
			form: null as unknown as HTMLFormElement,

			init() {
				this.$nextTick(() => {
					this.subtitle = this.modal?.$refs.subtitle as HTMLElement
					this.error = this.modal?.$refs.error as HTMLElement
					this.form = this.modal?.$refs.form

					this.form?.addEventListener('submit', this.handleSubmit.bind(this))
				})
			},

			async handleSubmit(e) {
				e.preventDefault()

				const formData = new FormData(e.target)
				const { email, variant } = formDataToObject(formData)

				const headers = new Headers()
				headers.append('content-type', 'application/json')
				headers.append('accept', 'application/json')
				headers.append('revision', '2024-07-15')
				const body = {
					data: {
						type: 'back-in-stock-subscription',
						attributes: {
							profile: {
								data: {
									type: 'profile',
									attributes: {
										email
									}
								}
							},
							channels: ['EMAIL']
						},
						relationships: {
							variant: {
								data: {
									type: 'catalog-variant',
									id: `$shopify:::$default:::${variant.toString()}`
								}
							}
						}
					}
				}

				try {
					const res = await fetch(`https://a.klaviyo.com/client/back-in-stock-subscriptions/?company_id=${KLAVIYO_COMPANY_ID}`, {
						method: 'POST',
						headers,
						body: JSON.stringify(body)
					})

					if (res.status === 202) {
						this.resetModalText()
						this.form.reset()

						setTimeout(() => {
							this.modal.showModal = false
						}, 2000)
					} else {
						throw new Error('Form submission failed.')
					}
				} catch (error) {
					this.onModalError()
				}
			},

			onError() {
				this.error?.classList.add('block')
				this.error?.classList.remove('hidden')
			},
			resetError() {
				this.error?.classList.remove('block')
				this.error?.classList.add('hidden')
			},

			onModalError() {
				this.onError()
				this.subtitle?.classList.remove('block')
				this.subtitle?.classList.add('hidden')
			},
			resetModalText() {
				this.resetError()
				this.subtitle?.classList.add('block')
				this.subtitle?.classList.remove('hidden')
			}
		}
	}
}
