export default {
	name: 'header',
	component() {
		return {
			currentPanelIndex: null,
			isDesktopNavOpen: false,
			isMobileNavOpen: false,
			isBannerHidden: false,
			device: this.$store.global.device,

			init() {
				window._lenis.on('scroll', this.handleScroll.bind(this))
			},
			closeAllPanels() {
				this.currentPanelIndex = null
			},
			handleLinkMouseEnter(e: MouseEvent) {
				const { target } = e

				if ((target as HTMLElement).getAttribute('aria-haspopup') !== 'true') {
					this.closeAllPanels()
					return
				}

				const { panelIndex } = (target as HTMLElement)?.dataset

				if (!panelIndex) {
					return
				}

				const index = parseInt(panelIndex, 10)
				this.currentPanelIndex = index
			},
			openDesktopNav() {
				this.isDesktopNavOpen = true
			},
			closeDesktopNav() {
				this.currentPanelIndex = null
				this.isDesktopNavOpen = false
			},
			handleBurgerClick() {
				this.isMobileNavOpen = !this.isMobileNavOpen
			},
			closeMobileNav() {
				this.isMobileNavOpen = false
			},
			handleScroll() {
				if (this.device !== 'desktop') return

				const scrollPosition = window._lenis?.actualScroll || 0
				const shouldBeHidden = scrollPosition > 500
				if (shouldBeHidden !== this.isBannerHidden) {
					this.isBannerHidden = shouldBeHidden
					document.documentElement.classList.toggle('global-banner-hidden')
				}
			}
		}
	}
}
