export default {
	name: 'predictiveSearch',
	component(props) {
		const { predictiveUrl } = props
		const predictiveSearchResults = this.$refs.predictiveSearchResults
		const suggestedSearchChildren = [...predictiveSearchResults.children]
		const suggestions = this.$refs.suggestions
		const input = this.$refs.input
		const domParser = new DOMParser()

		const reset = () => {
			suggestions.style.display = ''
			predictiveSearchResults.replaceChildren(...suggestedSearchChildren)
		}

		return {
			result: null,
			abortController: null,
			query: '',

			init() {
				this.$watch('$store.global.isSearchVisible', isOpen => {
					if (isOpen) {
						setTimeout(() => input?.focus(), 600)
					}
				})
			},

			getSearchResults(searchTerm) {
				if (this.abortController) this.abortController.abort('Fetch cancelled')

				if (searchTerm.length > 1) {
					this.abortController = new AbortController()
					this.result = fetch(`${predictiveUrl}?q=${searchTerm}&section_id=predictive-search&resources[limit]=9`, {
						signal: this.abortController.signal
					})
						.then(response => {
							if (!response.ok) {
								const error = new Error(response.status)
								throw error
							}

							return response.text()
						})
						.then(text => {
							const result = domParser.parseFromString(text, 'text/html')
							const searchResults = result?.getElementById('search-results')
							if (searchResults) {
								predictiveSearchResults.replaceChildren(...searchResults.children)
								if (suggestions) suggestions.style.display = 'none'
							} else {
								if (result) predictiveSearchResults.replaceChildren(...result.children)
								if (suggestions && suggestions.style.display !== '') {
									suggestions.style.display = ''
								}
							}
						})
						.catch(error => {
							throw error
						})
						.finally(() => {
							this.abortController = null
						})
				} else {
					reset()
				}
			},
			handleInputChange(e) {
				this.query = e.target.value
				this.getSearchResults(this.query)
			},
			handleClickReset() {
				if (this.abortController) this.abortController.abort('Fetch cancelled')
				input.value = ''
				this.query = ''
				input.focus()
				reset()
			},
			handleClickTerm(e) {
				this.query = e.target.textContent
				input.value = this.query
				this.getSearchResults(this.query)
			}
		}
	},
	init() {}
}
