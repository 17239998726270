import { quadInOut } from '@/utils/easings'
import { map } from '@/utils/maths'

export default {
	name: 'splitText',
	component() {
		const text = this.$el.textContent.trim()
		const words = text.replace(/\n/g, ' ___n ').split(' ')
		const chars = [] as HTMLSpanElement[]
		const spans = words
			.map((word, idx) => {
				if (word === '___n') {
					return '<br>'
				} else {
					const span = document.createElement('span')
					span.className = 'split-word'

					word.split('').forEach((char, innerIndex) => {
						const charSpan = document.createElement('span')
						charSpan.className = 'split-char'
						charSpan.textContent = char
						span.appendChild(charSpan)
						chars.push(charSpan)
					})

					if (idx !== words.length - 1) {
						const spaceSpan = document.createElement('span')
						spaceSpan.textContent = ' '
						span.appendChild(spaceSpan)
					}

					return span.outerHTML
				}
			})
			.join('')

		this.$el.innerHTML = spans
		return {
			init() {
				const newChars = [...this.$el.querySelectorAll('.split-char')]

				const charDuration = 1 / newChars.length
				newChars.forEach((char, index) => {
					const start = index * charDuration
					const end = (index + 4) * charDuration
					char.__start = start
					char.__end = end
				})

				this.$useScrollRatio(this.$el, ratio => {
					if (ratio < -1 || ratio > 0.75 || !newChars.length) return
					const _ratio = quadInOut(map(ratio, -0.7, 0.5, 0, 1))

					newChars.forEach(char => {
						const charOpacity = quadInOut(map(_ratio, char.__start, char.__end, 0.2, 1))
						char.style.opacity = `${charOpacity}`
					})
				})
			}
		}
	}
}
