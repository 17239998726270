export default {
	name: 'modal',
	component() {
		return {
			showModal: false,

			init() {
				this.$watch('showModal', shouldLock => {
					this.$store.global.bodyScrollLock(shouldLock)
				})

				this.$nextTick(() => {
					window?._app?.taxi?.on('NAVIGATE_OUT', () => {
						this.showModal = false
					})
				})
			},

			close() {
				this.showModal = false
			}
		}
	}
}
