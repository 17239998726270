export type FormDataObject = Record<string, string | string[]>
export const formDataToObject = (formData: FormData): FormDataObject =>
	Array.from(formData.entries()).reduce((accumulator, [key, value]) => ({ ...accumulator, [key]: value }), {})

/**
 * Convert key/value(s) object to a flatten array of key/value.
 * @example
 * `{family: ['jodie', 'ruby']}` => `[['family', 'jodie'], ['family', 'ruby']]`
 */
export const splitValues = (object: Record<string, string | string[]>) =>
	Object.entries(object).flatMap<string[]>(([key, value]) => (Array.isArray(value) ? value.map(_value => [key, _value]) : [[key, value]]))

type NotObject<T> = T extends object ? never : T
type IsObject<T> = T extends object ? T : never

/**
 * Convert flatten array of key/value to key/value(s) object.
 */
export const unsplitValues = array => {
	const object = {}
	array.forEach(([key, value]) => {
		const entry = object[key]
		if (entry) {
			object[key] = Array.isArray(entry) ? [...entry, value] : [entry, value]
		} else {
			object[key] = value
		}
	})
	return object
}

/**
 * Get a value from the first object with a non-null value at a given `key`.
 * @param objects Array of objects to get `prop` from. The order is the one the function looks for.
 * @param key Key of the property to get.
 */
export function get<TObj extends Record<string, unknown>, TKey extends keyof TObj>(
	objects: (TObj | undefined)[],
	key: TKey
): NonNullable<TObj[TKey]> | null

/**
 * Get a value from the first object with a non-null value at a given `key`.
 * @param objects Array of objects to get `prop` from. The order is the one the function looks for.
 * @param key Key of the property to get.
 * @param defaultValue If no value is found, return this default value.
 */
export function get<TObj extends Record<string, unknown>, TKey extends keyof TObj, TDefault extends TObj[TKey]>(
	objects: (TObj | undefined)[],
	key: TKey,
	defaultValue: NotObject<TDefault>
): NonNullable<TObj[TKey]> | TDefault

/**
 * Get a value from the first object with a non-null value at a given `key`.
 * @param objects Array of objects to get `prop` from. The order is the one the function looks for.
 * @param key Key of the property to get.
 * @param defaultValue If no value is found, return this default value.
 */
export function get<TObj extends Record<string, unknown>, TKey extends keyof TObj, TDefault extends Partial<TObj[TKey]>>(
	objects: (TObj | undefined)[],
	key: TKey,
	defaultValue: IsObject<TDefault>
): NonNullable<TObj[TKey]> | Partial<NonNullable<TObj[TKey]>>

export function get<TObj extends Record<string, unknown>, TKey extends keyof TObj, TDefault>(
	objects: (TObj | undefined)[],
	key: TKey,
	defaultValue: TDefault = null as TDefault
) {
	return objects.find(object => Boolean(object?.[key]))?.[key] || defaultValue
}
