export default {
	name: 'pdpHero',
	component() {
		return {
			isDescriptionExpanded: false,
			isCustomTextExpanded: false,
			isComplementaryOpen: false,
			currentInfosKey: null,
			infosButtonsEl: [],
			quantity: 1,
			quantitySelect: null,
			colorSelect: null,
			slideshow: null as { handleNavChange(index: number): void } | null,

			init() {
				this.infosButtonsEl = this.$el.querySelectorAll('[data-infos-button]')
				const firstInfosButton = [...this.infosButtonsEl].find(el => el.hasAttribute('data-infos-button-first'))
				if (firstInfosButton) {
					const firstInfosButtonKey = firstInfosButton.getAttribute('data-infos-button')
					this.currentInfosKey = firstInfosButtonKey
				}

				const variantSelector = document.getElementById('variantSelector')
				variantSelector?.addEventListener('change', this.onVariantChange.bind(this))

				this.$nextTick(() => {
					if (this.quantitySelect?.value) {
						this.$watch('quantitySelect.value', value => {
							this.quantity = parseInt(value || 1, 10)
						})
					}

					// these buttons can be found in the select-with-images file
					// which are used in the custom color dropdown
					const buttons = document.querySelectorAll('.select-dropdown-button')
					buttons?.forEach(button => {
						button.addEventListener('click', e => {
							this.onVariantChange.bind(this)({ target: e.currentTarget })
						})
					})
				})
			},

			toggleDescriptionExpanded() {
				this.isDescriptionExpanded = !this.isDescriptionExpanded
			},
			toggleCustomTextExpanded() {
				this.isCustomTextExpanded = !this.isCustomTextExpanded
			},
			setCurrentInfosKey(key) {
				this.currentInfosKey = key
			},

			onVariantChange({ target: optionValueElement }) {
				const optionValueIndex = optionValueElement.dataset.index
				const isColorSlideout = Boolean(optionValueElement.dataset.colorSlideout)
				if (optionValueIndex === '4') return

				const productElement = document.querySelector('.product')
				const variantSelector = document.getElementById('variantSelector')
				const idInput = document.querySelector('input[name="id"]') as HTMLInputElement

				const addToCartButton = document.querySelector('.add-to-cart-button')
				const titlePrice = document.querySelector('.title-price')

				const variantImage = document.querySelector('.variant-image')
				const variantNavImage = document.querySelector('.variant-nav-image')

				const sectionId = variantSelector?.dataset.sectionId
				const oldProductUrl = productElement!.dataset.productUrl
				const newProductUrl = optionValueElement.dataset.productUrl
				const newVariantId = optionValueElement.dataset.variantId

				let params = `section_id=${sectionId}&`
				if (newVariantId) {
					// If the option value has a variant selection, call the section rendering api for that variant
					params += `variant=${newVariantId}`
				} else {
					// If the option value selection has no variant, call the section rendering api with the selected option value IDs instead
					const selectedOptionValues = Array.from(productElement!.querySelectorAll('fieldset input:checked'))
						.map(element => element.dataset.optionValueId)
						.join(',')
					params += `option_values=${selectedOptionValues}`
				}

				// Call the section rendering api
				fetch(`${newProductUrl || ''}?${params}`)
					.then(response => response.text())
					.then(responseText => {
						const html = new DOMParser().parseFromString(responseText, 'text/html')

						if (newProductUrl && oldProductUrl !== newProductUrl) {
							// If the product changed, replace the old product section with the new product section
							productElement!.parentNode?.insertBefore(html.querySelector('.product')!, productElement)
							productElement!.remove()

							// Focus the input for the last clicked option value
							this.$el.querySelector(`[data-variant-id="${newVariantId}"`).focus()

							// Update anything else that depends on product, such as URL and recommended products
							return
						}

						const newVariantSelector = html.getElementById('variantSelector')
						const newTitlePrice = html.querySelector('.title-price')
						const newAddToCartButton = html.querySelector('.add-to-cart-button')
						const newIdInput = html.querySelector('input[name="id"]') as HTMLInputElement

						const newVariantImage = html.querySelector('.new-variant-image')
						const newVariantNavImage = html.querySelector('.new-variant-nav-image')

						// update variant selector block
						if (variantSelector && newVariantSelector) {
							variantSelector.innerHTML = newVariantSelector.innerHTML
						}

						// update product price inside the title block
						if (titlePrice && newTitlePrice) {
							titlePrice.innerHTML = newTitlePrice.innerHTML
						}

						// update add to cart button so it has the correct max quantity
						if (addToCartButton && newAddToCartButton) {
							// we need "outerHTML" here because `inventory_quantity` is stocked as an attribute in the <button>
							// sadly we can't use .replaceWith because while it's more performant, it'll remove event listeners attached to it
							addToCartButton.outerHTML = newAddToCartButton.outerHTML
						}

						// update id input
						if (newIdInput && idInput) {
							idInput.value = newIdInput.value
						}

						if (variantImage && newVariantImage) {
							const newVariantImageError = newVariantImage.querySelector('#section-error')
							if (!newVariantImageError) {
								variantImage.innerHTML = newVariantImage.innerHTML
							}
						}

						if (variantNavImage && newVariantNavImage) {
							const newVariantNavImageError = newVariantNavImage.querySelector('#section-error')
							if (!newVariantNavImageError) {
								variantNavImage.innerHTML = newVariantNavImage.innerHTML
							}
						}

						if (this.slideshow) {
							this.slideshow.handleNavChange(1)
						}

						// Focus the input for the last clicked option value
						if (!isColorSlideout) {
							const prevActiveOption = variantSelector?.querySelector(`[data-variant-id="${newVariantId}"`) as HTMLElement
							prevActiveOption?.focus()
						}
					})
					.catch(error => {
						console.error('Error:', error)
					})
			}
		}
	}
}
